<template>
  <div>
    <div class="top-nav-container">
      <div class="logo" v-if="showLogo">
        <router-link to="/home">
          <img src="../assets/image/nav/logo.png"/>
        </router-link>
      </div>
      <div class="back—title" v-if="title">
        <img @click="goBack" class="back_icon" src="../assets/image/nav/back_icon.png">
        {{ title }}
      </div>
      <div class="nav-right">
        <div class="but" @click="showLang = !showLang">
          {{ langName }}
        </div>
        <div class="nav_notice ">
          <div @click="toPage('/notice')">
            <img src="../assets/image/nav/notice.png">
            <div v-if="newNotice > 0" class="red_dot"></div>
          </div>
        </div>
        <div class="popup-but" @click="showIndexPopup()"></div>
      </div>
    </div>
    <div v-if="showHeight" class="top-nav-height"></div>

    <van-popup v-model="showIndexNav" position="right" :style="{ width: '80%',height:'100%'}">
      <div class="popup-cont">
        <div>
          <img class="logo-popup" src="../assets/image/nav/logo-popup.png">
        </div>
        <div class="user-info-cont" v-if="token && userInfo" @click="toPage('/personal')">
          <div class="user-info">
            <div class="grade-icon" v-if="navType == 'bank'">
<!--              <img src="../assets/image/bank/grade/grade6.png">-->
              <img :src="getImageUrl(bank_level_img)">



            </div>
            <div class="uid">
              UID:{{ userInfo ? userInfo.id : '-' }}
              <!--              <img v-if="showAdd" @click="showAdd =!showAdd" class="show_icon" src="../assets/image/index/not_show_icon.png"/>-->
              <!--              <img v-else class="show_icon" @click="showAdd =!showAdd" src="../assets/image/index/show_icon.png"/>-->
            </div>
            <div class="wallet-add">
              {{ $t('钱包地址') }}: {{ userInfo ? (showAdd ? userInfo.address : userInfo.hide_address) : '-' }}
            </div>
          </div>
          <!--          <img class="nav-bg" src="../assets/image/nav/nav-bg.png">-->
        </div>
        <div class="nav-cont">
          <div class="nav-item" @click="toPage('/')">
            <img class="icon" src="../assets/image/nav/icon1.png">
            <span> {{ $t('首页') }}</span>
            <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
          </div>
        </div>
        <div v-if="navType == 'index'">
          <div class="nav-cont">
            <div class="nav-item border-bottom" @click="toPage('/bank')">
              <img class="icon" src="../assets/image/nav/icon2.png">
              <span>{{ $t('银行') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
            <div class="nav-item" @click="toPage('/rwa')">
              <img class="icon" src="../assets/image/nav/icon3.png">
              <span>{{ $t('数字人') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>
          <div class="nav-cont" @click="stayTuned">
            <div class="nav-item border-bottom">
              <img class="icon" src="../assets/image/nav/icon4.png">
              <span>{{ $t('游戏') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
            <div class="nav-item" @click="stayTuned">
              <img class="icon" src="../assets/image/nav/icon5.png">
              <span>NFT</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>
          <div class="nav-cont" @click="toPage('/rwa/share')">
            <div class="nav-item">
              <img class="icon" src="../assets/image/nav/icon6.png">
              <span>{{ $t('邀请') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>
        </div>
        <div v-if="navType == 'bank'">
          <div class="nav-cont">
            <div class="nav-item" @click="toPage('/bank')">
              <img class="icon" src="../assets/image/nav/icon2.png">
              <span>{{ $t('银行') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>
          <div class="nav-cont">
            <div class="nav-item border-bottom" @click="toPage('/bank/storage')">
              <img class="icon" src="../assets/image/nav/icon13.png">
              <span>{{ $t('元储存') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
            <div class="nav-item" @click="stayTuned">
              <img class="icon" src="../assets/image/nav/icon12.png">
              <span>{{ $t('元交易') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>
          <div class="nav-cont" @click="toPage('/bank/invite')">
            <div class="nav-item">
              <img class="icon" src="../assets/image/nav/icon6.png">
              <span>{{ $t('邀请') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>

        </div>
        <div v-if="navType == 'rwa'">
          <div class="nav-cont">
            <div class="nav-item border-bottom" @click="toPage('/rwa')">
              <img class="icon" src="../assets/image/nav/icon7.png">
              <span>{{ $t('套餐') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
            <div class="nav-item" @click="toPage('/rwa/income')">
              <img class="icon" src="../assets/image/nav/icon8.png">
              <span>{{ $t('收益') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>
          <div class="nav-cont">
            <div class="nav-item border-bottom" @click="toPage('/rwa/community')">
              <img class="icon" src="../assets/image/nav/icon9.png">
              <span>{{ $t('社区') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
            <div class="nav-item" @click="toPage('/rwa/share')">
              <img class="icon" src="../assets/image/nav/icon10.png">
              <span>{{ $t('邀请') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>
          <div class="nav-cont">
            <div class="nav-item" @click="toPage('/rwa/personal')">
              <img class="icon" src="../assets/image/nav/icon11.png">
              <span>{{ $t('个人中心') }}</span>
              <img class="arrow-icon" src="../assets/image/nav/arrow_left.png">
            </div>
          </div>
        </div>
        <div class="out-but" v-if="token" @click="logOut()">
          {{ $t('退出登录') }}
        </div>
      </div>
    </van-popup>


    <van-popup v-model="showLang" position="bottom">
      <div class="lang-cont">
        <div class="close-icon">
          <img @click="showLang = !showLang" src="../assets/image/popup/close-icon.png">
        </div>
        <div class="lang-list">
          <div class="lang-item" v-for="(item,index) in  langArr" :key="index">
            <div class="lang">
              <div>{{ item.value2 }}</div>
              <!--              <div>中文</div>-->
            </div>
            <div class="checkbox-cont">
              <van-checkbox class="checkbox" @click="selectLanguage(item.key)" v-model="item.checkbox"
                            checked-color="#E7BD71"></van-checkbox>
            </div>
          </div>
        </div>
      </div>
    </van-popup>


  </div>
</template>

<script>
import Web3 from 'web3'
import {ethers} from 'ethers';

import {getToken} from '@/utils/auth'
import {mapState} from "vuex";

export default {
  name: "top-nav",
  data() {
    return {
      bank_level_img: 'grade0.png',
      showAdd: false,
      navType: 'index', //index 首页 rwa 数字人
      showIndexNav: false,
      showLang: false,
      walletAdd: this.$store.getters.walletAdd,
      signInPopup: false,
      langChecked: false,
      langArr: [
        {
          'key': 'en',
          'value': 'En',
          'value2': 'English',
          'checkbox': false,
        },
        {
          'key': 'zh_CN',
          'value': 'Cn',
          'value2': '简体中文',
          'checkbox': false,
        },
        {
          'key': 'ja',
          'value': 'Ja',
          'value2': '日本語',
          'checkbox': false,
        },
        {
          'key': 'ko',
          'value': 'Ko',
          'value2': '한국어',
          'checkbox': false,
        },
        // {
        //   'key': 'zh_TW',
        //   'value': '繁體中文'
        // }
      ],
      lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
      langEn: true,
      langCN: false,
      langName: 'En',
      that: this,
      answerItem: null,
      answer: -1,
      solution: null,
      token: getToken(),
      to_url: '',
      loginForm: {
        address: '',
        msg: '',
        sign: '',
        invite_code: ''
      },
      newNotice: 0
    }
  },
  props: {
    showHeight: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  filters: {
    addFilter(data) {
      if (data.length > 10) {
        return data.substr(0, 9) + '...' + data.substr(-4);
      } else {
        return '--'
      }

    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.app.userInfo
    })
  },
  watch: {
    '$store.getters.walletAdd'(newVal, oldVal) {
      // console.log(newVal);
      this.walletAdd = newVal
    },
    'showIndexNav'() {
      this.token = getToken()
    },
    '$store.app.userInfo'(newVal, oldVal) {
      // console.log(newVal);
      this.token = getToken()
      this.userInfo = newVal
    },
  },
  created() {
    this.getUserData()
    for (let item of this.langArr) {
      if (this.lang == item.key) {
        this.langName = item.value
        item.checkbox = true
      }
    }


    let new_notice = localStorage.getItem('new_notice')
    if (new_notice) {
      this.newNotice = new_notice
    }
  },
  methods: {
    stayTuned() {
      this.$toast(this.$t("敬请期待"));
    },
    goBack() {
      this.$router.go(-1);
    },
    toPage(path) {
      if (this.token || path == '/') {
        this.$router.push({
          path: path
        })
      } else {
        this.to_url = path
        this.sign_login()
      }
    },

    async sign_login() {
      let that = this;
      const web3 = new Web3(window.ethereum);
      window.ethereum.enable();

      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];

      var timestamp = new Date().getTime() + ''
      //
      const messageHash = web3.utils.sha3(timestamp);
      const message = 'Welcome to Bur'

      const signature = web3.eth.personal.sign(message, address).then(function (receipte) {
        console.log(receipte);

        // console.log(messageHash);
        let r = receipte.slice(0, 66)
        let s = '0x' + receipte.slice(66, 130)
        let v = '0x' + receipte.slice(130, 132)

        that.login({
          'address': address,
          'sign': receipte,
          'msg': message,
        })
      });
    },
    login(data) {
      let that = this;
      this.loginForm.address = data.address
      this.loginForm.msg = data.msg;
      this.loginForm.sign = data.sign;
      let invite_code = sessionStorage.getItem('invite_code')
      if (invite_code) {
        this.loginForm.invite_code = invite_code;
      }

      this.$store.dispatch('app/setWalletAdd', data.address)

      this.$store.dispatch('app/login', this.loginForm)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              that.token = res.data.token
              that.getUserInfo()
              that.$router.push({path: that.to_url});
            } else {
              that.$toast(res.message);
            }

          })
          .catch(() => {

          })
    },

    getUserInfo() {
      if (!this.userInfo) {
        this.$toast.loading({
          message: '',
          duration: 0,
          forbidClick: true,
        });
      }
      this.$store.dispatch('app/getUserInfo')
          .then((res) => {
            let add = this.$store.getters.walletAdd
            if (add && add != res.data.address) {
              console.log('------');
              window.ethereum.isConnected()
              this.$store.dispatch('app/logOut')
            } else {
              console.log(this.$store.getters.walletAdd);
            }
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
    getUserData() {
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('app/getUserInfo')
          .then((data) => {
            console.log('getUserInfo-----', data);
            if (data.code == 200) {
              this.bank_level_img = 'grade'+ data.data.bank_level +'.png'
              this.getImageUrl(this.bank_level_img)
            }
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
    getImageUrl(filename) {
      return require(`../assets/image/bank/grade/${filename}`);
    },
    logOut() {
      window.ethereum.isConnected()
      this.$store.dispatch('app/logOut')
    },
    showIndexPopup() {
      console.log(this.$route.path);
      let path = this.$route.path
      if (path.includes('/rwa')) {
        this.navType = 'rwa'
      } else if (path.includes('/bank')) {
        this.navType = 'bank'
      } else {
        this.navType = 'index'
      }
      this.showIndexNav = true;

    },
    selectLanguage(lang) {
      this.showLang = false
      for (let item of this.langArr) {
        console.log(item);
        if (this.lang == item.key) {
          item.checkbox = true
        } else {
          item.checkbox = false
        }
      }
      localStorage.setItem('lang', lang);

      window.location.reload()
    },


  },


}
</script>

<style scoped lang="less">
.top-nav-height {
  height: 3.8rem;
}

.top-nav-container {
  position: fixed;
  background-color: #000000;
  width: 100%;
  height: 3.8rem;
  top: 0;
  left: 0;
  padding: 0 1rem;
  box-sizing: border-box;
  z-index: 99;

  .logo {
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    width: 7.39rem;
    height: 3.8rem;

    img {
      width: 100%;
    }
  }

  .back—title {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: #E7BD71;
    height: 1.63rem;

    .back_icon {
      position: relative;
      left: 1rem;
      float: left;
      height: 1.63rem;
    }
  }

  .nav-right {
    position: relative;
    float: right;
    text-align: right;
    height: 3.8rem;
    display: flex;
    align-items: center;

    .but {
      position: relative;
      float: left;
      font-size: 0.80rem;
      font-weight: bold;
      text-align: center;
      min-width: 1.54rem;
      top: 0.1rem;
      height: 1.25rem;
      color: #987534;
      line-height: 0.89rem;
      border: 0.18rem solid #987534;
      border-radius: 0.35rem;
      background-repeat: repeat;
      box-sizing: border-box;
    }

    .popup-but {
      position: relative;
      float: right;
      margin-left: 1rem;
      display: inline-block;
      width: 1.43rem;
      height: 1.43rem;
      background-image: url("../assets/image/nav/nav_icon_menu.png");
      background-repeat: repeat;
      background-size: 100% 100%;

    }

    .lang-cont {
      position: absolute;
      top: 2rem;
      padding-top: 0.5rem;
      padding-bottom: 0.2rem;
      width: 4.81rem;
      text-align: center;
      z-index: 9;
      font-weight: 400;
      font-size: 0.66rem;
      background-color: rgba(0, 0, 0, 0.8);

      .lang-item {
        padding: 0.33rem 0;
      }
    }
  }
}

.popup-cont {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1.13rem 1.43rem;
  box-sizing: border-box;
  background-color: #101010;

  .logo-popup {
    height: 1.7rem;
    margin-bottom: 5vh;
  }

  .user-info-cont {
    position: relative;
    margin-bottom: 1rem;
    padding: 1.3rem 0;
    background: url("../assets/image/nav/nav-bg.png");
    background-size: 100% 100%;

    .user-info {
      //position: absolute;
      //width: 100%;
      //top: 0;
      //bottom: 0;
      //left: 0;
      padding-left: 5vw;
      box-sizing: border-box;
      margin: auto;
      //height: 4rem;

      .grade-icon {
        height: 1.18rem;
        margin-bottom: 0.6rem;

        img {
          height: 100%;
        }
      }

      .uid {
        font-size: 1.29rem;
        color: #E7BD71;
        line-height: 1.5rem;
        padding-bottom: 1vh;
      }

      .show_icon {
        padding-left: 3vw;
        width: 1.5rem;
      }

      .wallet-add {
        font-size: 0.86rem;
        color: #E7BD71;
        line-height: 1.5rem;
      }
    }

    .nav-bg {
      width: 100%;
    }
  }

  .nav-cont {
    background: #1B1B1B;
    padding: 0.5rem 0 0.5rem 1.36rem;
    box-sizing: border-box;
    border-radius: 1.43rem;
    margin-bottom: 1rem;

    .nav-item {
      position: relative;
      overflow: hidden;
      font-size: 1rem;
      padding: 0.9rem 0;
      color: #E7BD71;
      line-height: 1.29rem;

      .icon {
        float: left;
        width: 1.29rem;
        height: 1.29rem;
        margin-right: 1.13rem;
      }

      span {
        float: left;
      }

      .arrow-icon {
        float: right;
        padding-right: 1.7rem;
        width: 0.5rem;
      }
    }

    .border-bottom:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 96%;
      height: 1px;
      background-color: #262526;
    }
  }

  .out-but {
    padding: 1.3rem 0;
    background: #1B1B1B;
    text-align: center;
    font-size: 1.14rem;
    border-radius: 1.43rem;
    color: #E7BD71;
  }

}


.nav_notice {
  position: relative;
  margin-left: 1rem;
  display: inline-block;
  width: 1.43rem;
  height: 1.43rem;

  img {
    width: 100%;
  }

  .red_dot {
    position: absolute;
    right: -0.5rem;
    top: -0.3rem;
    width: 0.5rem;
    height: 0.5rem;
    background: #FF3E3E;
    border-radius: 50%;
  }
}

.lang-cont {
  min-height: 21.25rem;
  background: #1B1B1B;
  border-radius: 1.07rem 1.07rem 0rem 0rem;

  .close-icon {
    padding: 1.45rem 1rem;
    text-align: right;

    img {
      width: 1.05rem;
    }
  }

  .lang-list {
    padding: 0 1.03rem 2.5rem 1.03rem;

    .lang-item {
      overflow: hidden;
      padding: 0.8rem 0 0.9rem 1rem;
      font-size: 0.93rem;
      line-height: 1.6rem;
      color: #E7BD71;
      border-bottom: 1px solid #262526;

      .lang {
        width: 60%;
        line-height: 3.2rem;
        float: left;
      }

      .checkbox-cont {
        position: relative;
        width: 30%;
        height: 3.2rem;
        float: right;

        .checkbox {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }

        :deep( .van-checkbox__icon .van-icon) {
          border-color: #E7BD71;
        }

        :deep( .van-checkbox__icon--checked .van-icon) {
          color: #000;
        }
      }
    }
  }
}
</style>
